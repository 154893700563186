import React from 'react';
import {BannerMainWrapper, BannerWrapper} from "./banner.style";
import Container from "../../common/components/UI/Container";
import Box from "../../elements/Box";
import Fade from "react-reveal/Fade";
import Heading from "../../elements/Heading";

const BannerCommunity = () => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <BannerMainWrapper>
          <Box className="banner-content">
            <Fade bottom>
              <Heading as="h4" content="Join our community to discuss Crema, get updates and access to the latest versions."/>
            </Fade>
          </Box>
        </BannerMainWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default BannerCommunity;