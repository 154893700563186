import styled from 'styled-components';

const CommunitySectionWrapper = styled.section`
  padding: 60px 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 50px 0 20px;
  }
  @media (max-width: 575px) {
    padding: 40px 0 10px;
  }
`;

export default CommunitySectionWrapper;

export const CommunityRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  
  & .community-col {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    width: 100%;
    
    @media (min-width: 992px) {
      width: 50%;
    }
    
    @media (min-width: 1200px) {
      width: 33.33%;
    }
    
    & .react-reveal {
      height: 100%;
    }
  }
`;

export const CommunityCard = styled.div`
    background-color: #e6f4fb;
    color: #7e868a;
    padding: 50px 20px;
    text-align: center;
    border-radius: 10px;
    height: 100%;
    
    @media (min-width: 1600px) {
      padding: 50px;
    }
    
    @media (max-width: 575px) {
      padding: 30px;
    }
    
    & a {
      color: #7e868a;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    & img {
      margin-bottom: 15px;
    }
    
    & h3 {
      margin-bottom: 5px;
      font-size: 20px;
      color: #000000;
      font-weight: bold;
    }
    
    & p {
      margin: 0 0 15px;
      font-size: 14px;
    }
    
    & .btn {
      border-radius: 30px;
    }
`;
