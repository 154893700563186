import React from 'react';
import Box from '../../elements/Box';
import Image from '../../elements/Image';
import Container from '../../common/components/UI/Container';

import CommunitySectionWrapper, {CommunityCard, CommunityRow} from './community.style';
import GitHubIcon from '../../../static/images/community/git-hub-icon.png';
import SlackIcon from '../../../static/images/community/slack.png';
import DocIcon from '../../../static/images/community/doc-icon.png';
import Fade from "react-reveal/Fade";
import Button from "../../elements/Button";

const CommunitySection = () => {
  return (
    <CommunitySectionWrapper>
      <Container>
        <CommunityRow>

          <Box className='community-col'>
            <Fade left>
              <CommunityCard>
                <a href="https://docs.cremawork.com/" target="_blank" rel='noreferrer'>
                  <Image src={DocIcon} alt='doc-icon'/>
                  <h3>
                    Documentation
                  </h3>
                  <p>
                    Available our detailed document abd top call support here.
                  </p>
                  <Button className='btn' title='Check Documents'/>
                </a>
              </CommunityCard>
            </Fade>
          </Box>

          <Box className='community-col'>
            <Fade bottom>
              <CommunityCard>
                <a href="https://git-access.cremawork.com/" target="_blank" rel='noreferrer'>
                  <Image src={GitHubIcon} alt='doc-icon'/>
                  <h3>
                    GitHub
                  </h3>
                  <p>
                    Get latest feature, make pull request or bug fixes.
                  </p>
                  <Button className='btn' title='Join our GitHub Repo'/>
                </a>
              </CommunityCard>
            </Fade>
          </Box>
          <Box className='community-col'>
            <Fade right>
              <CommunityCard>
                <a href="https://cremaworld.slack.com/join/shared_invite/zt-g85fup6f-IMXXqsMx0BP08QBN3nxBWA#/" target="_blank" rel='noreferrer'>
                  <Image src={SlackIcon} alt='doc-icon'/>
                  <h3>
                    Slack
                  </h3>
                  <p>
                   Share your idea and insights, for inspiration collaboration and great result.
                  </p>
                  <Button className='btn' title='Join our Community'/>
                </a>
              </CommunityCard>
            </Fade>
          </Box>
        </CommunityRow>
      </Container>
    </CommunitySectionWrapper>
  );
};

export default CommunitySection;
