import React, {Fragment} from 'react';
import {ThemeProvider} from "styled-components";
import {CremaTheme} from "../../theme";
import SEO from "../../components/seo";
import {ResetCSS} from "../../common/assets/css/style";
import {ContentWrapper, GlobalStyle} from "../../containers/sassModern.style";
import Sticky from "react-stickynode";
import {DrawerProvider} from "../../common/contexts/DrawerContext";
import Navbar from "../../containers/Navbar";
import Footer from "../../containers/Footer";
import {graphql, useStaticQuery} from "gatsby";
import BannerCommunity from "../../containers/BannerCommunity";
import CommunitySection from "../../containers/CommunitySection";

const Community = () => {

  const Data = useStaticQuery(graphql`
    query {
      hipsterJson {
        menuItems {
          label
          path
          offset
          staticLink
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={CremaTheme}>
      <Fragment>
        <SEO
          title="Crema React Admin Template."
          description="Crema React is available with Material-UI, Ant Design and Bootstrap-5 available with create react app and next.js"
          keywords={[
            "Material Admin Theme",
            "MUI Admin Theme",
            "Admin Theme with TypeScript",
            "Ant Design Admin Theme",
            "Bootstrap Admin Theme",
            "React Admin Theme Typescript",
            "React Admin Theme Context API",
            "Redux and Context Api Template",
            "Pure React hooks based Template",
            "Academic Dashboard",
            "Analytics Dashboard",
            "Health Care Dashboard",
            "CRM Dashboard",
            "Crypto Dashboard",
            "Ecommerce Dashboard",
            "Email Application",
            "Calendar Application",
            "TODO/Task Application",
            "Scrum board Application",
            "Chat Application",
            "Profile/Wall Application",
            "Google Map",
            "React Calendar",
            "Firebase",
            "Aws Cognito",
            "Auth0",
            "JWT Auth",
          ]}
        />

        <ResetCSS/>
        <GlobalStyle/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar menuItems={Data.hipsterJson.menuItems}/>
            </DrawerProvider>
          </Sticky>
          <BannerCommunity/>

          <CommunitySection />

          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Community;