import styled  from "styled-components";

import bannerImg from "../../images/header/banner-bg.png";

export const BannerWrapper = styled.section`
  padding: 120px 0 80px;
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  color: #fff
  
  @media (min-width: 576px) {
    padding: 150px 0 50px;
  }
  
  @media (min-width: 768px) {
    padding-top: 180px;
  }
  
  @media (min-width: 1200px) {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .banner-shape {
    z-index: 2;
  }
`;

export const BannerMainWrapper = styled.div`
  text-align: center;
  
  & .banner-content {
    max-width: 800px;
    margin: 0 auto 30px;
      
    & h4 {
      font-size: 24px;
      color: #fff;
      font-weight: normal;
      line-height: 1.4;

      @media (min-width: 768px) {
          font-size: 32px;
      }

      @media (min-width: 992px) {
          font-size: 36px;
      }
    } 
  }
`;
